import { createContext, ReactNode, useState } from "react"
import {
  KEY_STORED_SETTINGS,
  KEY_STORED_SETTINGS_LUNCH_MINUTES,
  KEY_STORED_SETTINGS_WORKDAY_END,
  KEY_STORED_SETTINGS_WORKDAY_START as KEY_WORKDAY_START,
  KEY_STORED_SETTINGS_WORKING_HOURS as KEY_WORKING_HOURS,
} from "./localSettingsConstants"

export interface ISettings {
  workdayStart: string
  workdayEnd: string
  workingHours: number
  lunchMinutes: number
}

const defaultData: ISettings = {
  [KEY_WORKDAY_START]: "08:00",
  [KEY_STORED_SETTINGS_WORKDAY_END]: "17:00",
  [KEY_WORKING_HOURS]: 8,
  [KEY_STORED_SETTINGS_LUNCH_MINUTES]: 30,
}

const defaultContext = {
  settings: defaultData,
  setSettings: async (settings: ISettings) => {},
}

export const SettingsContext = createContext(defaultContext)

export function SettingsContextProvider({ children }: { children: ReactNode }) {
  const [settings, _setSettings] = useState(getStoredSettings())

  // TODO: hook up sync with cloud
  async function setSettings(settings: ISettings) {
    localStorage.setItem(KEY_STORED_SETTINGS, JSON.stringify(settings))
    _setSettings(settings)
  }

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

function getStoredSettings(): ISettings {
  const storedSettings = localStorage.getItem(KEY_STORED_SETTINGS)
  if (!storedSettings) {
    return defaultData
  }
  return { ...defaultData, ...JSON.parse(storedSettings) }
}
