import Card from "components/Card/Card"
import { useLogStore } from "model/Context/LogStoreContext/useLogStore"
import { SettingsContext } from "model/Context/SettingsContext/SettingsContext"
import { draftStorage } from "model/LocalStorage/draftStorage"
import {
  calcFlex,
  calcTimeWorked,
  DayReport,
  getLocalDate,
  getLocalTime,
  h2hmString,
} from "model/timedata/timedata"
import { useContext, HTMLProps, ReactNode, StyleHTMLAttributes, useState } from "react";
import { uuidv4 } from "util/uuid"
import "./TimeCard.css"

/** Utility component to create a label/input pair */
interface IInput {
  value: string
  type: "text" | "date" | "time" | "number"
  label: ReactNode
  onChange: (e: any) => void
  button?: ReactNode
  inputStyle?: StyleHTMLAttributes<HTMLInputElement>
  inputProps?: HTMLProps<HTMLInputElement>
}
function Input({
  value = "",
  type = "text",
  label = "Input",
  onChange = (e: any) => {},
  inputStyle = {},
  inputProps = {},
  button = null,
}: IInput) {
  const id = uuidv4()
  return (
    <div className="TimeCard-Input">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="TimeCard-Input-Flex">
        <input
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          style={{ ...inputStyle }}
          {...inputProps}
        />
        {button && button}
      </div>
    </div>
  )
}

const dateToday = () => new Date().toISOString()
const getDateOnly = (date: string) => date.split("T")[0]

function TimeCard({
  title,
  onSave,
  report,
  draft,
}: {
  title?: string
  onSave: (report: DayReport) => Promise<void> | void
  report?: DayReport
  draft?: DayReport | null
}) {
  const logStore = useLogStore()
  const { settings } = useContext(SettingsContext)

  const [showConfirmRemove, setShowConfirmRemove] = useState("")
  const [mDate, setDate] = useState(
    getDateOnly(draft?.date ?? report?.date ?? new Date().toISOString())
  )
  const [mArrive, setArrive] = useState(
    draft?.arrive ?? report?.arrive ?? settings.workdayStart
  )
  const [mLeave, setLeave] = useState(
    draft?.leave ?? report?.leave ?? settings.workdayEnd
  )
  const [mBreakTime, setBreakTime] = useState(
    draft?.lunch ?? report?.lunch ?? settings.lunchMinutes
  )
  const [mWorkingHours, setWorkingHours] = useState(
    draft?.workingHours ?? report?.workingHours ?? settings.workingHours
  )
  const [mComment, setComment] = useState(
    draft?.comment ?? report?.comment ?? ""
  )
  const [saving, setSaving] = useState(false)

  const flex = calcFlex({
    id: "not relevant",
    date: mDate,
    arrive: mArrive,
    leave: mLeave,
    lunch: typeof mBreakTime === "undefined" ? 30 : mBreakTime,
    workingHours: mWorkingHours,
  })

  const worked = calcTimeWorked({
    id: "not relevant",
    date: mDate,
    arrive: mArrive,
    leave: mLeave,
    lunch: typeof mBreakTime === "undefined" ? 30 : mBreakTime,
  })

  const resetToDefaults = () => {
    setDate(getDateOnly(dateToday()))
    setArrive(settings.workdayStart)
    setLeave(settings.workdayEnd)
    setBreakTime(settings.lunchMinutes)
    setComment("")
    setWorkingHours(settings.workingHours)
    return true
  }

  const saveDraft = () => {
    const draft: DayReport = {
      id: "draft",
      date: mDate,
      arrive: mArrive,
      leave: mLeave,
      lunch: mBreakTime,
      workingHours: mWorkingHours,
      comment: mComment,
    }
    console.log({ draft })
    draftStorage.set(draft)
  }

  const saveClicked = async () => {
    const mReport: DayReport = {
      id: report?.id ?? new Date().toISOString(),
      date: mDate,
      arrive: mArrive,
      leave: mLeave,
      lunch: Number(mBreakTime ?? 30),
      workingHours: mWorkingHours,
      comment: mComment,
    }
    if (!report) {
      resetToDefaults()
      draftStorage.clear()
    }
    setSaving(true)
    try {
      await onSave(mReport)
    } finally {
      setSaving(false)
    }
  }

  const removeLog = async (id: string) => {
    setSaving(true)
    try {
      await logStore.remove(id)
    } catch (e) {
      console.error(e)
      setSaving(false)
    }
  }

  const getCurrentButton = (onClick: () => void) => {
    // ⟳↻
    if (report) return null
    return (
      <button
        type="button"
        title="Update"
        onClick={onClick}
        style={{ marginLeft: "10px" }}
      >
        <span role="img" aria-label="update" style={{ fontSize: "1.25em" }}>
          ⇄
        </span>
      </button>
    )
  }

  if (showConfirmRemove && report) {
    return (
      <Card className="TimeCard-confirm">
        <div>Are you sure you want to remove this card?</div>
        <button onClick={() => setShowConfirmRemove("")}>Keep</button>
        <button onClick={() => removeLog(showConfirmRemove)}>Remove</button>
      </Card>
    )
  }

  return (
    <Card className="TimeCard">
      {title && (
        <h3 style={{ textAlign: "center", marginTop: "0" }}>{title}</h3>
      )}
      <form
        className="TimeCardForm"
        onSubmit={(e) => {
          e.preventDefault()
          saveClicked()
        }}
      >
        <Input
          type="date"
          label="Date"
          value={mDate}
          onChange={(e: any) => setDate(e.target.value)}
          button={getCurrentButton(() => {
            setDate(getLocalDate())
          })}
        />
        <Input
          type="time"
          label="In"
          value={mArrive}
          onChange={(e) => setArrive(e.target.value)}
          button={getCurrentButton(() => {
            setArrive(getLocalTime())
          })}
        />
        <Input
          type="time"
          label="Out"
          value={mLeave}
          onChange={(e) => setLeave(e.target.value)}
          button={getCurrentButton(() => {
            setLeave(getLocalTime())
          })}
        />
        <Input
          type="number"
          label={
            <span>
              Lunch break <small style={{ opacity: ".5" }}>(minutes)</small>
            </span>
          }
          value={String(mBreakTime)}
          onChange={(e) => Number(setBreakTime(e.target.value))}
          inputProps={{ step: "5", min: "0", placeholder: "Time in minutes" }}
          button={getCurrentButton(() => setBreakTime(settings.lunchMinutes))}
        />

        <Input
          type="number"
          label="Working hours"
          value={String(mWorkingHours)}
          onChange={(e) => setWorkingHours(e.target.value)}
          inputProps={{ step: "1", min: "0", placeholder: "8 hours" }}
          button={getCurrentButton(() =>
            setWorkingHours(settings.workingHours)
          )}
        />

        <Input
          type="text"
          label="Comment"
          value={String(mComment)}
          onChange={(e) => setComment(e.target.value)}
          inputProps={{ placeholder: "Additional info" }}
        />
      </form>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{ textAlign: "center" }}>Worked {h2hmString(worked)}</div>
        <div style={{ textAlign: "center" }}>Flex {h2hmString(flex)}</div>
      </div>

      <div style={{ display: "flex", marginTop: "10px" }}>
        {report ? (
          <button
            type="button"
            style={{ margin: "1rem auto 0 auto", display: "block" }}
            // onClick={async () => removeLog(report.id)}
            onClick={() => setShowConfirmRemove(report.id)}
            disabled={saving}
          >
            Remove
          </button>
        ) : (
          <button
            type="button"
            style={{ margin: "1rem auto 0 auto", display: "block" }}
            onClick={saveDraft}
          >
            Save draft
          </button>
        )}
        <button
          type="submit"
          style={{ margin: "1rem auto 0 auto", display: "block" }}
          onClick={saveClicked}
          disabled={saving}
        >
          {report ? "Update" : "Save new report"}
        </button>
      </div>
    </Card>
  )
}

export default TimeCard
