import { useDropboxStore } from "model/Context/DropboxContext/dropboxStore"
import { IStoreShape } from "model/Context/TimeLogContext/localStore"
import { useTimeLogStore } from "model/Context/TimeLogContext/TimeLogContext"
import { DayReport } from "model/timedata/timedata"
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { debug } from "util/logger"

// FIXME: Make this a setting that can be configured
const autoDropboxSave = true

interface ILogStoreContext {
  cloudConnected: boolean
  cloudDownload: () => Promise<void>
  busy: boolean
  set: (store: IStoreShape) => Promise<void>
  save: (item: DayReport) => Promise<void>
  remove: (id: string) => Promise<void>
  workLog: DayReport[]
  storageData: IStoreShape
}

export const LogStoreContext = createContext({} as ILogStoreContext)

// let singleTon: ReturnType<typeof useLogStore>
export function LogStoreProvider({ children }: { children: ReactNode }) {
  const localStore = useTimeLogStore()
  const dropbox = useDropboxStore()
  const [busy, setBusy] = useState(false)
  async function _upload(newStore: IStoreShape) {
    // save to dropbox, if available
    if (autoDropboxSave) {
      await dropbox.upload?.(newStore)
    }
  }

  // TODO: Check if useCallback is needed
  const cloudDownload = useCallback(async () => {
    async function _cloudDownload() {
      setBusy(true)
      const dropboxStore = await dropbox.download?.()
      // TODO: use dropboxStore?.last_updated to resolve sync conflicts?
      if (dropboxStore?.log) {
        console.log("setting local store from dropbox")
        localStore.setStore?.(dropboxStore.log)
      }
      setBusy(false)
    }
    return _cloudDownload()
  }, [dropbox, localStore])

  // This effect will download the cloud storage upon first initialization
  // TODO: Consider doing in a more elegant fashion?
  useEffect(() => {
    function initialDownload() {
      if (dropbox.newInitialized && !dropbox.syncing) {
        debug.log("downloading initial cloud storage")
        dropbox.setNewInitialized(false)
        cloudDownload().catch(() => {
          debug.log("initial download error")
          dropbox.setNewInitialized(true)
        })
      }
    }
    initialDownload()
  }, [cloudDownload, dropbox])

  async function save(item: DayReport) {
    setBusy(true)
    const newStore = localStore.saveItem(item) // save to localStorage
    await _upload(newStore) // save to cloud if available
    setBusy(false)
  }

  async function set(store: IStoreShape) {
    setBusy(true)
    localStore.setStore(store) // save to localStorage
    await _upload(store) // save to cloud if available
    setBusy(false)
  }

  async function remove(id: string) {
    const newStore = localStore.removeLog(id)
    await _upload(newStore)
  }

  const workLog: DayReport[] = useMemo(() => {
    return Object.values(localStore.log)
  }, [localStore.log])

  const context = {
    cloudConnected: dropbox.connected,
    cloudDownload,

    busy: busy || dropbox.syncing,
    set,
    save,
    remove,

    workLog,
    storageData: localStore.log,
  }

  return (
    <LogStoreContext.Provider value={context}>
      {children}
    </LogStoreContext.Provider>
  )
}
