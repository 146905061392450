import { Dropbox, DropboxAuth } from "dropbox"
import { IStoreShape } from "model/Context/TimeLogContext/localStore"
import {
  useCallback,
  useState,
  createContext,
  useEffect,
  useContext,
  ReactNode,
} from "react"
import { debug } from "util/logger"
import {
  dropboxDownload,
  dropboxUpload,
  getAuthedDropbox,
  getDropboxAuth,
  getOauthCodeFromUrl,
  getStoredAuthData,
  IDropboxStore,
  initAuthWithDropbox,
  revokeAccess,
} from "./dropbox"

interface IDropboxError {
  error: Error
  retry: () => void
}

interface IDropboxContext {
  connected: boolean
  syncing: boolean
  initializing?: boolean
  initPKCEAuth?: () => Promise<void>
  download: () => Promise<IDropboxStore | undefined>
  upload?: (log: IStoreShape) => Promise<void>
  revoke: () => Promise<void>
  error?: IDropboxError | null
  newInitialized?: boolean
  setNewInitialized: (newInitialized: boolean) => void
}

const blankContext: IDropboxContext = {
  connected: false,
  revoke: async () => {},
  syncing: false,
  download: async () => undefined,
  setNewInitialized: () => {},
}

const DropboxContext = createContext<IDropboxContext>(blankContext)

export function DropboxProvider({ children }: { children: ReactNode }) {
  const [dropboxAuth] = useState<DropboxAuth>(getDropboxAuth())
  const [syncing, setSyncing] = useState(false)
  const [initializing, setInitializing] = useState(false)
  const [dropbox, setDropbox] = useState<Dropbox | null>(
    !!getStoredAuthData()?.access_token
      ? new Dropbox({ auth: dropboxAuth })
      : null
  )
  const [error, setError] = useState<IDropboxError | null>(null)
  const urlCode = getOauthCodeFromUrl()
  const [newInitialized, setNewInitialized] = useState(false)

  useEffect(() => {
    async function go() {
      console.log("Initializing dropbox")
      setInitializing(true)
      const dbx = await getAuthedDropbox(dropboxAuth)
      if (!dbx) {
        console.warn("Could not init connection with dropbox")
        return
      }
      setDropbox(dbx)
      // FIXME: Download data immediately after auth
      setInitializing(false)
      console.log("Initializing dropbox: done")
      setNewInitialized(true)
    }
    if (urlCode) {
      go()
    }
  }, [dropboxAuth, urlCode])

  async function initPKCEAuth() {
    initAuthWithDropbox(dropboxAuth)
  }

  async function _upload(log: IStoreShape, attempts = 0) {
    if (attempts >= 3) {
      console.error(`Failed to upload to dropbox after ${attempts} attempts`)
      return
    }
    if (!dropbox) {
      console.warn("Cannot sync to cloud; Dropbox auth not set")
      return
    }
    setSyncing(true)
    console.log("Init upload")
    const store: IDropboxStore = {
      last_updated: String(new Date()),
      log,
    }
    try {
      console.log("Preparing payload")
      const data = JSON.stringify(store)
      console.log("Uploading to dropbox")
      await dropboxUpload(dropbox, data)
      console.log("Upload done")
      setError(null)
    } catch (e) {
      const error = e as Error
      // FIXME: Test this
      console.error("Error uploading to dropbox", error)
      setError({
        error,
        retry: () => {
          console.warn("Retrying upload")
          _upload(log, attempts + 1)
        },
      })
    } finally {
      setSyncing(false)
    }
  }
  const upload = !dropbox ? undefined : _upload
  // const download = !dropbox ? undefined : () => _download()

  // TODO: Check if useCallback is needed
  const download = useCallback(() => {
    async function _download(dropbox: Dropbox) {
      if (syncing) {
        debug.info("Already syncing")
        return
      }
      setSyncing(true)
      try {
        debug.log("download running", new Date().toISOString())
        const data = await dropboxDownload(dropbox)
        debug.log("download done", new Date().toISOString())
        return data
      } catch (err) {
        // FIXME: Error handling
        console.error(err)
      } finally {
        setSyncing(false)
      }
    }
    if (dropbox) {
      return _download(dropbox)
    }
    return blankContext.download()
  }, [dropbox, syncing])

  async function revoke() {
    if (!dropbox) return
    revokeAccess(dropbox)
    setDropbox(null)
  }

  return (
    <DropboxContext.Provider
      value={{
        syncing,
        initializing,
        connected: !!dropbox && !!getStoredAuthData().refresh_token,
        initPKCEAuth,
        download,
        upload,
        revoke,
        error,
        newInitialized,
        setNewInitialized,
      }}
    >
      {children}
    </DropboxContext.Provider>
  )
}

export function useDropboxStore() {
  return useContext(DropboxContext)
}
