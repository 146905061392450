interface IStorage<Data> {
  get: () => Data | null
  set: (d: Data) => void
  clear: () => void
  serialize: (d: Data) => string
  deserialize: (s: string) => Data
}

export function createLocalStorage<Data>(
  key: string,
  override?: IStorage<Data>
): IStorage<Data> {
  const storage = localStorage
  return {
    deserialize(s) {
      return JSON.parse(s)
    },
    serialize(d) {
      return JSON.stringify(d)
    },
    get() {
      const s = storage.getItem(key)
      return s ? this.deserialize(s) : null
    },
    set(d) {
      storage.setItem(key, this.serialize(d))
    },
    clear() {
      storage.removeItem(key)
    },
    ...override,
  }
}
