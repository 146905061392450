import { ReactNode } from "react"

export function Section({
  loading,
  children,
}: {
  loading: boolean
  children: ReactNode
}) {
  return (
    <section style={{ opacity: loading ? "0.5" : "1" }}>{children}</section>
  )
}
