import { useLogStore } from "model/Context/LogStoreContext/useLogStore"
import { useEffect, useRef } from "react"
import { debug } from "util/logger"

export function SyncListener() {
  const { cloudDownload } = useLogStore()

  const ref = useRef<HTMLDivElement | null>(null)

  function handleVisible() {
    focusListener.set(cloudDownload)
    cloudDownload()
  }

  function handleHidden() {
    focusListener.clear()
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting === true) {
          debug.log("Element is fully visible in screen")
          handleVisible()
        } else {
          debug.log("Element is not visible in screen")
          handleHidden()
        }
      },
      { threshold: [1] }
    )

    if (ref.current) {
      observer.observe(ref?.current)
    } else {
      console.log("observer not initialized")
    }

    return () => {
      console.log("Removing observer")
      observer.disconnect()
      ref.current = null
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div ref={ref} />
}

const focusListener: IFocusListener = {
  name: "Window Focus Listener",
  element: window,
  set(cb: () => Promise<void>) {
    debug.info("Setting", this.name)
    this.element.onfocus = async () => {
      debug.info(this.name, "got focus")
      if (this.busy) {
        debug.info(this.name, "already busy")
      }
      this.busy = true
      await cb()
      this.busy = false
    }
    this.element.onblur = () => {
      debug.info(this.name, "lost focus")
    }
  },
  clear() {
    debug.info("Clearing", this.name)
    this.element.onfocus = null
  },
  get active() {
    return !!this.element.onfocus
  },
}

interface IFocusListener {
  element: Window | Document
  set(cb: () => Promise<void>): void
  clear(): void
  active: boolean
  busy?: boolean
  name: string
}


// Use for testing instead of e.g., dropbox
// async function syncListenCallback() {
//   try {
//     console.log("download running", new Date().toISOString())
//     await new Promise((resolve) => setTimeout(resolve, 2000))
//     console.log("download done", new Date().toISOString())
//   } catch (e) {
//     console.error(e)
//   }
// }
