import TimeCard from "components/Card/TimeCard"
import { DayReport } from "model/timedata/timedata"
import './Worklog.css'

const Worklog = ({
  workLog,
  saveClicked,
}: {
  workLog: DayReport[]
  saveClicked: (report: DayReport) => void
}) => {
  return (
    <div className="Worklog">
      {workLog
        .sort((l1, l2) => (l1.date <= l2.date ? 1 : -1))
        .map((item) => {
          return <TimeCard key={item.id} report={item} onSave={saveClicked} />
        })}
    </div>
  )
}
export default Worklog
