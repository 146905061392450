import { ComponentPropsWithoutRef } from "react";
import "./Card.css"

function Card({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<"div">) {
  const cls = "Card " + (className || "")
  return (
    <div className={cls} {...props}>
      {children}
    </div>
  )
}

export default Card
