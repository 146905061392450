import { Dropbox, DropboxAuth } from "dropbox"
import { IStoreShape } from "model/Context/TimeLogContext/localStore"
import { IS_PRODUCTION } from "util/processUtil"

export const DROPBOX_DATA_PATH = IS_PRODUCTION
  ? "/flextime.json"
  : "/test-flextime.json"
const DROPBOX_LOCAL_AUTH_DATA = "dropbox auth"

export async function revokeAccess(dbx: Dropbox) {
  await dbx.authTokenRevoke()
  localStorage.removeItem(DROPBOX_LOCAL_AUTH_DATA)
}

export async function dropboxUpload(dbx: Dropbox, contents = "") {
  console.log("Uploading", DROPBOX_DATA_PATH)
  try {
    const response = await dbx.filesUpload({
      path: DROPBOX_DATA_PATH,
      contents,
      mode: { ".tag": "overwrite" },
    })
    // console.log(response)
    return response
  } catch (uploadErr) {
    // FIXME: Handle errors
    console.log(uploadErr)
  }
}

export async function dropboxDownload(
  dbx: Dropbox,
  path = DROPBOX_DATA_PATH
): Promise<IDropboxStore> {
  try {
    console.log("Downloading", DROPBOX_DATA_PATH)
    const response = await dbx.filesDownload({ path })
    // console.log(response.result)
    // @ts-ignore - fileBlob is not typed
    const blob: Blob | undefined = response.result.fileBlob
    const text = await new Response(blob).text()
    return JSON.parse(text)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const DROPBOX_SYNC_PARAM = "doSync=true"
const APP_BASE_URI =
  window.location.origin +
  (process.env.NODE_ENV === "production" ? "/time-report/" : "")
// const REDIRECT_URI = `${APP_BASE_URI}?${DROPBOX_SYNC_PARAM}`
const REDIRECT_URI = `${APP_BASE_URI}`
const CLIENT_ID = "os5g1mtn4iwbdp8"

export function getStoredAuthData() {
  const dropboxData = localStorage.getItem(DROPBOX_LOCAL_AUTH_DATA)
  const authData: Partial<IDropboxAuthData> = JSON.parse(dropboxData || "{}")
  return authData
}

let dbxAuth: DropboxAuth
export function getDropboxAuth() {
  if (dbxAuth) {
    return dbxAuth
  }

  const authData = getStoredAuthData()
  dbxAuth = new DropboxAuth({
    clientId: CLIENT_ID,
    accessToken: authData?.access_token,
    refreshToken: authData?.refresh_token,
  })
  return dbxAuth
}

export async function initAuthWithDropbox(dbxAuth: DropboxAuth) {
  try {
    const authUrl = await dbxAuth.getAuthenticationUrl(
      REDIRECT_URI,
      undefined,
      "code",
      "offline",
      undefined,
      undefined,
      true
    )

    const codeVerifier = dbxAuth.getCodeVerifier()
    window.sessionStorage.setItem("codeVerifier", codeVerifier)
    window.location.href = String(authUrl)
  } catch (error) {
    console.log(error)
  }
}

// Parses the url and gets the access token if it is in the urls hash
export function getOauthCodeFromUrl() {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get("code")
}

// If the user was just redirected from authenticating, the urls hash will
// contain the access token.
export function hasRedirectedFromAuth() {
  return !!getOauthCodeFromUrl()
}

function removeCodeFromUrl() {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.delete("code")
  window.history.replaceState("", "", window.location.origin)
}

export async function getAuthedDropbox(dbxAuth: DropboxAuth) {
  const codeVerifier = window.sessionStorage.getItem("codeVerifier")
  if (!codeVerifier) {
    return null
  }
  const urlCode = getOauthCodeFromUrl()
  if (!urlCode) {
    return null
  }
  removeCodeFromUrl()
  dbxAuth.setCodeVerifier(codeVerifier)
  try {
    const response = await dbxAuth.getAccessTokenFromCode(REDIRECT_URI, urlCode)
    const result = response.result as IDropboxAuthData
    localStorage.setItem(DROPBOX_LOCAL_AUTH_DATA, JSON.stringify(result))

    const accessToken = result.access_token
    dbxAuth.setAccessToken(accessToken)
    console.log("creating new dbx")
    const dbx = new Dropbox({
      auth: dbxAuth,
    })
    console.log("... done!")

    const l = await dbx.filesListFolder({
      path: "",
    })
    console.log(l)
    return dbx
  } catch (error) {
    console.log(error)
  }
}

interface IDropboxAuthData {
  uid: string
  access_token: string
  expires_in: number
  token_type: "bearer"
  scope: string
  refresh_token: string
  account_id: string
}

export interface IDropboxStore {
  last_updated: string // date-string
  log: IStoreShape
}
