import TimeCard from "components/Card/TimeCard"
import FlexTotal from "components/FlexTotal/FlexTotal"
import { Options } from "components/Options/Options"
import { Section } from "components/Section/Section"
import Worklog from "components/Worklog/Worklog"
import { useLogStore } from "model/Context/LogStoreContext/useLogStore"
import { SettingsContext } from "model/Context/SettingsContext/SettingsContext"
import { draftStorage } from "model/LocalStorage/draftStorage"
import { DayReport } from "model/timedata/timedata"
import { SyncListener } from "components/SyncListener/SyncListener"
import { useContext } from "react";
import "./MainView.css"

export function MainView() {
  const logStore = useLogStore()
  const draft = draftStorage.get()
  const { settings } = useContext(SettingsContext)
  const workLog: DayReport[] = logStore.workLog

  const saveClicked = async (item: DayReport) => {
    await logStore.save(item)
  }

  return (
    <div className="App">
      <header className="shadow standard">
        <FlexTotal worklog={workLog} syncing={logStore.busy} />
      </header>

      <main className="App-main">
        <SyncListener />
        <Section loading={logStore.busy}>
          <section className="MainView-AddLog">
            <TimeCard
              // this is to force TimeCard to update if settings change
              key={JSON.stringify(settings)}
              draft={draft}
              title="New report"
              onSave={saveClicked}
            />
          </section>

          <section style={{ paddingBottom: "30vh" }}>
            <h2>Log</h2>
            <Worklog saveClicked={saveClicked} workLog={workLog} />
          </section>
        </Section>
      </main>

      <aside className="App-aside">
        <Options />
      </aside>
    </div>
  )
}
