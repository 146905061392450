import { IS_DEVELOP } from "util/processUtil"

const debugLogOn = IS_DEVELOP

let log = (...a: any[]) => {}
let warn = (...a: any[]) => {}
let error = (...a: any[]) => {}

if (debugLogOn) {
  log = console.log.bind(window.console, "[Debug]")
  warn = console.warn.bind(window.console, "[Debug]")
  error = console.error.bind(window.console, "[Debug]")
}

export const debug = {
  log,
  warn,
  error,
  info: debugLogOn ? console.info.bind(window.console, "[Debug]") : () => {},
}
