import "./SyncIndicator.css"
import { ReactComponent as SyncIcon } from "./cloud-sync-1.svg"

type TOptional = boolean | undefined

export default function SyncIndicator({ visible }: { visible: TOptional }) {
  // ⟳
  // ↻
  // return <div className="SyncIndicator">⟳</div>
  return (
    <div className="SyncIndicator">
      <SyncIcon style={{ visibility: visible ? "revert" : "hidden" }} />
    </div>
  )
}
