import { useContext, useState } from "react";
import "./DefaultSettings.css"
import { SettingsContext } from "model/Context/SettingsContext/SettingsContext"

export function DefaultSettings() {
  const { settings, setSettings } = useContext(SettingsContext)
  const [workdayStart, setWorkdayStart] = useState(settings.workdayStart)
  const [workdayEnd, setWorkdayEnd] = useState(settings.workdayEnd)
  const [workingHours, setWorkingHours] = useState(settings.workingHours)
  const [lunchMinutes, setLunchMinutes] = useState(settings.lunchMinutes)

  function handleWorkdayStart(timeOfDay: string) {
    setWorkdayStart(timeOfDay)
  }

  function handleWorkingHours(hours: string) {
    setWorkingHours(Number(hours))
  }

  function handleSave() {
    const settings = {
      workdayStart,
      workdayEnd,
      workingHours,
      lunchMinutes,
    }
    console.log("saving", settings)
    setSettings(settings)
  }

  return (
    <div className="DefaultSettings-Box">
      <div className="DefaultSettings-InputBox">
        <label>
          <div>Workday start</div>
          <input
            name={"Workday start"}
            type="time"
            value={workdayStart}
            onChange={(e) => handleWorkdayStart(e.target.value)}
          />
        </label>

        <label>
          <div>Workday end</div>
          <input
            name={"Workday end"}
            type="time"
            value={workdayEnd}
            onChange={(e) => setWorkdayEnd(e.target.value)}
          />
        </label>

        <label>
          <div>Working hours</div>
          <input
            name={"Working hours"}
            type="number"
            min="1"
            max="23"
            value={workingHours}
            onChange={(e) => handleWorkingHours(e.target.value)}
          />
        </label>

        <label>
          <div>Lunch in minutes</div>
          <input
            name={"Lunch in minutes"}
            type="number"
            min="1"
            max="23"
            value={lunchMinutes}
            onChange={(e) => setLunchMinutes(Number(e.target.value))}
          />
        </label>
      </div>

      <button onClick={() => handleSave()}>Save</button>
    </div>
  )
}
