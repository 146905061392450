import { MainView } from "components/MainView/MainView"
import { SettingsContextProvider } from "model/Context/SettingsContext/SettingsContext"
import { StorageContextProvider } from "model/Context/TimeLogContext/TimeLogContext"
import { DropboxProvider } from "model/Context/DropboxContext/dropboxStore"
import "./Global.css"
import { LogStoreProvider } from "model/Context/LogStoreContext/LogStore"

function App() {
  return (
    <ProviderWrapper>
      <MainView />
    </ProviderWrapper>
  )
}

function ProviderWrapper({ children }: { children: React.ReactNode }) {
  return (
    <StorageContextProvider>
      <SettingsContextProvider>
        <DropboxProvider>
          <LogStoreProvider>
            {/* x */}
            {children}
          </LogStoreProvider>
        </DropboxProvider>
      </SettingsContextProvider>
    </StorageContextProvider>
  )
}

export default App
