import { useDropboxStore } from "model/Context/DropboxContext/dropboxStore"
import { DROPBOX_DATA_PATH } from "model/Context/DropboxContext/dropbox"
import "./DropboxSettings.css"
import { useLogStore } from "model/Context/LogStoreContext/useLogStore"

export function DropboxSettings() {
  const dbContext = useDropboxStore()
  const logStore = useLogStore()

  async function handleDownload() {
    logStore.cloudDownload()
  }

  async function connectDropbox() {
    console.log("Connecting to dropbox")
    dbContext.initPKCEAuth?.()
  }

  return (
    <div className={"DropboxSettings"}>
      <section>
        {dbContext.connected ? (
          <button
            onClick={() => dbContext.revoke()}
            disabled={dbContext.syncing}
          >
            Disconnect Dropbox
          </button>
        ) : (
          <button onClick={connectDropbox} disabled={dbContext.initializing}>
            Enable Dropbox sync
          </button>
        )}
        {!dbContext.connected && (
          <div>
            Note: If you have already linked to Dropbox previously, enabling
            sync here will <em>overwrite all local data</em> with what you have
            stored in the cloud.
          </div>
        )}
      </section>

      {dbContext.connected && (
        <section className={"DropboxSettings-connected"}>
          <div>
            Dropbox filename: <pre>{DROPBOX_DATA_PATH}</pre>
          </div>

          <button onClick={handleDownload} disabled={dbContext.syncing}>
            Download data from cloud
          </button>

          <button
            onClick={() => dbContext.upload?.(logStore.storageData)}
            disabled={dbContext.syncing}
          >
            Upload data to cloud
          </button>
        </section>
      )}
    </div>
  )
}
