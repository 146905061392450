import { DayReport } from "model/timedata/timedata"
import {
  createContext,
  ReactNode,
  useState,
  useCallback,
  useContext,
} from "react"
import {
  getFullLog,
  IStoreShape,
  removeLog,
  saveLog,
  setStore as setLocalStore,
} from "./localStore"

const defaultContext = {
  log: getFullLog(),
  setStore: setLocalStore,
  saveItem: saveLog,
  removeLog,
}
const StorageContext = createContext(defaultContext)

export function StorageContextProvider({ children }: { children: ReactNode }) {
  const [log, setMemLog] = useState(getFullLog())

  function saveItem(item: DayReport) {
    const newLog = saveLog(item)
    setMemLog(newLog)
    return newLog
  }

  function remove(id: string) {
    const removedLog = removeLog(id)
    setMemLog(removedLog)
    return removedLog
  }

  const setStore = useCallback((store: IStoreShape) => {
    function _setStore() {
      setMemLog(store)
      setLocalStore(store)
      return store
    }
    return _setStore()
  }, [])

  return (
    <StorageContext.Provider
      value={{
        ...defaultContext,
        saveItem,
        removeLog: remove,
        setStore,
        log,
      }}
    >
      {children}
    </StorageContext.Provider>
  )
}

export function useTimeLogStore() {
  return useContext(StorageContext)
}
