import Card from "components/Card/Card"
import { ImportExport } from "components/ImportExport/ImportExport"
import { DropboxSettings } from "components/Dropbox/DropboxSettings"
import { DefaultSettings } from "components/Options/DefaultSettings/DefaultSettings"
import './Options.css'

export function Options() {
  return (
    <section className="option-section">
      <h1>Options</h1>

      <Card>
        <h3>Defaults</h3>
        <DefaultSettings />
      </Card>

      <Card>
        <h3>Local backup</h3>
        <ImportExport />
      </Card>

      <Card>
        <h3>Dropbox cloud backup</h3>
        <DropboxSettings />
      </Card>
    </section>
  )
}
