import SyncIndicator from "components/SyncIndicator/SyncIndicator"
import { calcTotalFlex, DayReport, h2hm } from "model/timedata/timedata"
import "./FlexTotal.css"

function FlexTotal({
  worklog,
  syncing,
}: {
  worklog: DayReport[]
  syncing?: boolean
}) {
  const totalFlex = calcTotalFlex(worklog)
  const hhmm = h2hm(totalFlex)
  const flexStr = `${hhmm.h}h ${hhmm.m}m`
  return (
    <h2 className="FlexTotal">
      <span>Total Flex {flexStr}</span>
      <SyncIndicator visible={syncing} />
    </h2>
  )
}

export default FlexTotal
