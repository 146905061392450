// more info:
// https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications
import { useLogStore } from "model/Context/LogStoreContext/useLogStore"
import {
  getAppData,
  IAppData,
  TIME_REPORT_DATA,
} from "model/Context/TimeLogContext/localStore"
import { useRef, useState } from "react";
import "./ImportExport.css"

async function generateDownloadURL(data: IAppData) {
  const url = await URL.createObjectURL(
    new File([JSON.stringify(data, null, 2)], "backup.json", {
      type: "application/json",
    })
  )
  return url
}

export function ImportExport() {
  const logStore = useLogStore()
  const [downloadUrl, setDownloadUrl] = useState("#")
  const filePickerRef = useRef(null)
  const downloadLinkRef = useRef(null)

  const exportClicked = async () => {
    const url = await generateDownloadURL(getAppData())
    setDownloadUrl(url)
    if ((downloadLinkRef?.current as any).click) {
      console.log("clicking")
      ;(downloadLinkRef.current as any).click()
    }
    URL.revokeObjectURL(url)
  }

  const importClicked = () => {
    ;(filePickerRef?.current as any).click()
  }

  const fileSelected = async (e: any) => {
    const file: any = e.target.files[0]
    const text = await file.text()
    const appData: IAppData = JSON.parse(text)
    const store = appData[TIME_REPORT_DATA]
    logStore.set(store)
    // TODO: Temporarily disable window based auto cloud download while the file
    // picker for import is open; otherwise there will be syncing race
    // conditions
  }

  return (
    <div className="ImportExport">
      <button onClick={exportClicked} style={{ fontSize: "1.25rem" }}>
        Export
      </button>
      {downloadUrl && (
        <a
          href={downloadUrl}
          download={`${new Date().toISOString()}.time-report.json`}
          style={{ display: "none" }}
          ref={downloadLinkRef}
        >
          Download data
        </a>
      )}

      <input
        type="file"
        id="input"
        accept=".time-report.json"
        style={{ display: "none" }}
        onChange={fileSelected}
        ref={filePickerRef}
      />

      <button style={{ fontSize: "1.25rem" }} onClick={importClicked}>
        Import
      </button>
    </div>
  )
}
