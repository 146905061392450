// Localstorage interface
import { DayReport } from "model/timedata/timedata"

export interface IStoreShape {
  [key: string]: DayReport
}

export const TIME_REPORT_DATA = "__time_report_data"
export const TIME_REPORT_DRAFT = "__time_report_draft"
export const VALID_KEYS = [TIME_REPORT_DATA, TIME_REPORT_DRAFT]

const validate = (key: string) => {
  return VALID_KEYS.includes(key)
}

function save(key: string, value: string) {
  if (!validate(key)) {
    throw new Error(`${key} is not a valid storage key`)
  }
  localStorage.setItem(key, value)
}

function _getStorage(): IStoreShape {
  return JSON.parse(localStorage.getItem(TIME_REPORT_DATA) || "{}")
}

export function getFullLog() {
  return _getStorage()
}

export function getLogItem(id: string) {
  return _getStorage()[id] || null
}

export function setStore(data: IStoreShape) {
  save(TIME_REPORT_DATA, JSON.stringify(data))
}

export function saveLog(data: DayReport) {
  const currentData = _getStorage()
  currentData[data.id] = data
  save(TIME_REPORT_DATA, JSON.stringify(currentData))
  return currentData
}

export function removeLog(id: string) {
  const currentData = _getStorage()
  const updatedData = Object.fromEntries(
    Object.entries(currentData).filter(([storedId, _]) => storedId !== id)
  )
  save(TIME_REPORT_DATA, JSON.stringify(updatedData))
  return updatedData
}

// TODO: Implement Save Draft
export function getDraft(): DayReport | null {
  const draft = localStorage.getItem(TIME_REPORT_DRAFT)
  if (!draft) return null
  return JSON.parse(draft)
}

export interface IAppData {
  [TIME_REPORT_DATA]: IStoreShape
}

export function getAppData(): IAppData {
  let data = {
    [TIME_REPORT_DATA]: getFullLog(),
  }
  return data
}

export function setAppData(data: IAppData) {
  for (const [key, val] of Object.entries(data)) {
    console.log("saving", { key, val })
    save(key, JSON.stringify(val))
  }
}
