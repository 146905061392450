export const exampleData: DayReport = {
  id: "2020-02-09T19:27:38.624Z", // date of report, UTC
  date: "2020-02-09", // date of report, yyyy-mm-dd
  arrive: "08:30", // arrival time, ISO hh:mm
  leave: "17:30", // departure time, ISO hh:mm
  lunch: 30, // break time in minutes
  comment: "",
}

export interface DayReport {
  // cannot type strings until resolving
  // https://github.com/Microsoft/TypeScript/issues/6579
  id: string
  date: string // yyyy-mm-dd
  arrive: string // HH:mm
  leave: string // HH:mm
  lunch?: number
  comment?: string
  workingHours?: number // always assume 8h unless this is set
}

export function defaultReport(storedSettings?: Partial<DayReport>): DayReport {
  const id = new Date().toISOString()
  const date = id.split("T")[0]
  return {
    id,
    date,
    arrive: storedSettings?.arrive ?? "08:00",
    leave: storedSettings?.leave ?? "17:00",
    lunch: storedSettings?.lunch ?? 30,
    workingHours: storedSettings?.workingHours ?? 8,
    comment: "",
  }
}

export function calcTimeWorked({ arrive, leave, lunch }: DayReport) {
  const arriveDate = new Date(`1970-01-01T${arrive}`)
  const leaveDate = new Date(`1970-01-01T${leave}`)
  const breakTimeMillis = (lunch || 0) * 60 * 1000
  const diff = leaveDate.getTime() - arriveDate.getTime() - breakTimeMillis
  return diff / 3.6e6
}

export function calcFlex(report: DayReport) {
  return calcTimeWorked(report) - (report.workingHours || 8)
}

export function h2hm(hours: number) {
  const getHours = () => (hours < 0 ? Math.ceil : Math.floor)(hours) + 0 // add 0 to deal wiht -0
  const h = getHours()
  const m = Math.round((hours * 60) % 60)
  return { h, m }
}

export function h2hmString(hours: number) {
  const hm = h2hm(hours)
  return `${hm.h}h ${hm.m}m`
}

export function calcTotalFlex(reports: DayReport[]) {
  const t = reports.reduce((hours, report) => {
    return hours + calcFlex(report)
  }, 0)
  return Number(t.toFixed(3))
}

function isNil(o: any) {
  return o === null || o === undefined
}

export function getLocalISOTime(d = new Date(), offset?: number) {
  const calcOffset = isNil(offset) ? d.getTimezoneOffset() : (offset as number)
  const tzoffset = calcOffset * 60000 //offset in milliseconds
  const localISOTime = new Date(d.getTime() - tzoffset)
    .toISOString()
    .slice(0, -1)
  return localISOTime
}

export function getLocalDate(d = new Date(), offset?: number) {
  return getLocalISOTime(d, offset).split("T")[0]
}

export function getLocalTime(d = new Date(), offset?: number) {
  return getLocalISOTime(d, offset)
    .split("T")[1]
    .split(":")
    .slice(0, 2)
    .join(":")
}
